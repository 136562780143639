// import 'vue-awesome/icons'
import 'vue-awesome/icons/sync'
import 'vue-awesome/icons/arrow-left'

import Icon from 'vue-awesome/components/Icon'

Icon.register({
  // company: {
  //   width: 16.67,
  //   height: 15,
  //   d: 'M9.00008 3.83333V0.5H0.666748V15.5H17.3334V3.83333H9.00008ZM4.00008 13.8333H2.33341V12.1667H4.00008V13.8333ZM4.00008 10.5H2.33341V8.83333H4.00008V10.5ZM4.00008 7.16667H2.33341V5.5H4.00008V7.16667ZM4.00008 3.83333H2.33341V2.16667H4.00008V3.83333ZM7.33342 13.8333H5.66675V12.1667H7.33342V13.8333ZM7.33342 10.5H5.66675V8.83333H7.33342V10.5ZM7.33342 7.16667H5.66675V5.5H7.33342V7.16667ZM7.33342 3.83333H5.66675V2.16667H7.33342V3.83333ZM15.6667 13.8333H9.00008V12.1667H10.6667V10.5H9.00008V8.83333H10.6667V7.16667H9.00008V5.5H15.6667V13.8333ZM14.0001 7.16667H12.3334V8.83333H14.0001V7.16667ZM14.0001 10.5H12.3334V12.1667H14.0001V10.5Z'
  // },
  // report: {
  //   width: 20,
  //   height: 20,
  //   d: 'M19.1666 6.66667C19.1666 7.58333 18.4166 8.33333 17.4999 8.33333C17.3499 8.33333 17.2083 8.31667 17.0749 8.275L14.1083 11.2333C14.1499 11.3667 14.1666 11.5167 14.1666 11.6667C14.1666 12.5833 13.4166 13.3333 12.4999 13.3333C11.5833 13.3333 10.8333 12.5833 10.8333 11.6667C10.8333 11.5167 10.8499 11.3667 10.8916 11.2333L8.76658 9.10833C8.63325 9.15 8.48325 9.16667 8.33325 9.16667C8.18325 9.16667 8.03325 9.15 7.89992 9.10833L4.10825 12.9083C4.14992 13.0417 4.16658 13.1833 4.16658 13.3333C4.16658 14.25 3.41658 15 2.49992 15C1.58325 15 0.833252 14.25 0.833252 13.3333C0.833252 12.4167 1.58325 11.6667 2.49992 11.6667C2.64992 11.6667 2.79159 11.6833 2.92492 11.725L6.72492 7.93333C6.68325 7.8 6.66658 7.65 6.66658 7.5C6.66658 6.58333 7.41658 5.83333 8.33325 5.83333C9.24992 5.83333 9.99992 6.58333 9.99992 7.5C9.99992 7.65 9.98325 7.8 9.94158 7.93333L12.0666 10.0583C12.1999 10.0167 12.3499 10 12.4999 10C12.6499 10 12.7999 10.0167 12.9333 10.0583L15.8916 7.09167C15.8499 6.95833 15.8333 6.81667 15.8333 6.66667C15.8333 5.75 16.5833 5 17.4999 5C18.4166 5 19.1666 5.75 19.1666 6.66667Z'
  // },
  // alert: {
  //   width: 20,
  //   height: 20,
  //   d: 'M12.5 5H2.5V6.66667H12.5V5ZM12.5 8.33333H2.5V10H12.5V8.33333ZM2.5 13.3333H9.16667V11.6667H2.5V13.3333ZM14.1667 5V11.8167C13.9083 11.725 13.625 11.6667 13.3333 11.6667C11.95 11.6667 10.8333 12.7833 10.8333 14.1667C10.8333 15.55 11.95 16.6667 13.3333 16.6667C14.7167 16.6667 15.8333 15.55 15.8333 14.1667V6.66667H18.3333V5H14.1667Z'
  // },
  // alertSetting: {
  //   width: 20,
  //   height: 20,
  //   d: 'M8.34167 17.5084C8.34167 18.425 9.08333 19.1667 10 19.1667C10.9167 19.1667 11.6583 18.425 11.6583 17.5084H8.34167ZM15.7333 14.0167V9.16669C15.7333 6.45835 13.8583 4.19169 11.325 3.59169V2.99169C11.325 2.25835 10.7333 1.66669 10 1.66669C9.26667 1.66669 8.675 2.25835 8.675 2.99169V3.59169C6.14167 4.19169 4.26667 6.45835 4.26667 9.16669V14.0167L2.5 15.7834V16.6667H17.5V15.7834L15.7333 14.0167ZM13.3333 10.8417H10.8333V13.3417H9.16667V10.8417H6.66667V9.16669H9.16667V6.66669H10.8333V9.16669H13.3333V10.8417Z'
  // },
  // alertDestination: {
  //   width: 20,
  //   height: 20,
  //   d: 'M2.5 4.16667V15.8333C2.5 16.75 3.24167 17.5 4.16667 17.5H15.8333C16.75 17.5 17.5 16.75 17.5 15.8333V4.16667C17.5 3.25 16.75 2.5 15.8333 2.5H4.16667C3.24167 2.5 2.5 3.25 2.5 4.16667ZM12.5 7.5C12.5 8.88333 11.3833 10 10 10C8.61667 10 7.5 8.88333 7.5 7.5C7.5 6.11667 8.61667 5 10 5C11.3833 5 12.5 6.11667 12.5 7.5ZM5 14.1667C5 12.5 8.33333 11.5833 10 11.5833C11.6667 11.5833 15 12.5 15 14.1667V15H5V14.1667Z'
  // },
  // alertSettings: {
  //   width: 20,
  //   height: 20,
  //   d: 'M8.34167 17.5082C8.34167 18.4248 9.08333 19.1665 10 19.1665C10.9167 19.1665 11.6583 18.4248 11.6583 17.5082H8.34167ZM15.7333 14.0165V9.1665C15.7333 6.45817 13.8583 4.1915 11.325 3.5915V2.9915C11.325 2.25817 10.7333 1.6665 10 1.6665C9.26667 1.6665 8.675 2.25817 8.675 2.9915V3.5915C6.14167 4.1915 4.26667 6.45817 4.26667 9.1665V14.0165L2.5 15.7832V16.6665H17.5V15.7832L15.7333 14.0165ZM13.3333 10.8415H10.8333V13.3415H9.16667V10.8415H6.66667V9.1665H9.16667V6.6665H10.8333V9.1665H13.3333V10.8415Z'
  // },

  // gateway: {
  //   width: 20,
  //   height: 20,
  //   d: 'M2.70033 5.12501C2.09199 5.35834 1.66699 5.97501 1.66699 6.66668V16.6667C1.66699 17.5833 2.40866 18.3333 3.33366 18.3333H16.667C17.592 18.3333 18.3337 17.5833 18.3337 16.6667V6.66668C18.3337 5.74168 17.592 5.00001 16.667 5.00001H6.91699L13.8003 2.21668L13.2337 0.833344L2.70033 5.12501ZM5.83366 16.6667C4.45033 16.6667 3.33366 15.55 3.33366 14.1667C3.33366 12.7833 4.45033 11.6667 5.83366 11.6667C7.21699 11.6667 8.33366 12.7833 8.33366 14.1667C8.33366 15.55 7.21699 16.6667 5.83366 16.6667ZM16.667 10H15.0003V8.33334H13.3337V10H3.33366V6.66668H16.667V10Z'
  // },
  // sensor: {
  //   width: 20,
  //   height: 20,
  //   d: 'M12.4999 7.5H7.49993C7.0416 7.5 6.6666 7.875 6.6666 8.33333V18.3333C6.6666 18.7917 7.0416 19.1667 7.49993 19.1667H12.4999C12.9583 19.1667 13.3333 18.7917 13.3333 18.3333V8.33333C13.3333 7.875 12.9583 7.5 12.4999 7.5ZM9.99994 12.5C9.08327 12.5 8.33327 11.75 8.33327 10.8333C8.33327 9.91667 9.08327 9.16667 9.99994 9.16667C10.9166 9.16667 11.6666 9.91667 11.6666 10.8333C11.6666 11.75 10.9166 12.5 9.99994 12.5ZM5.87494 5.04167L7.04994 6.21667C7.80827 5.46667 8.84994 5 9.99994 5C11.1499 5 12.1916 5.46667 12.9499 6.21667L14.1249 5.04167C13.0666 3.98333 11.6083 3.33333 9.99994 3.33333C8.3916 3.33333 6.93327 3.98333 5.87494 5.04167ZM9.99994 0C7.4666 0 5.17493 1.025 3.5166 2.68333L4.6916 3.85833C6.04994 2.50833 7.92493 1.66667 9.99994 1.66667C12.0749 1.66667 13.9499 2.50833 15.2999 3.86667L16.4749 2.69167C14.8249 1.025 12.5333 0 9.99994 0Z'
  // },
  // tablet: {
  //   width: 20,
  //   height: 20,
  //   d: 'M0.841341 5.83332L0.833008 14.1667C0.833008 15.0833 1.58301 15.8333 2.49967 15.8333H17.4997C18.4163 15.8333 19.1663 15.0833 19.1663 14.1667V5.83332C19.1663 4.91666 18.4163 4.16666 17.4997 4.16666H2.49967C1.58301 4.16666 0.841341 4.91666 0.841341 5.83332ZM15.833 5.83332V14.1667H4.16634V5.83332H15.833Z'
  // },
  // toilet: {
  //   width: 20,
  //   height: 20,
  //   d: 'M14.6965 1.66663H15.2173C15.8336 1.66663 16.3333 2.1664 16.3333 2.78271V9.70283H14.6965V2.78271V1.66663ZM16.3337 18.3344V10.2982H3.33337C3.33337 13.448 5.3461 17.0703 8.29747 18.3344H16.3337Z'
  // },
  // website: {
  //   width: 20,
  //   height: 20,
  //   d: 'M16.6666 3.33337H3.33329C2.41663 3.33337 1.67496 4.08337 1.67496 5.00004L1.66663 15C1.66663 15.9167 2.41663 16.6667 3.33329 16.6667H16.6666C17.5833 16.6667 18.3333 15.9167 18.3333 15V5.00004C18.3333 4.08337 17.5833 3.33337 16.6666 3.33337ZM12.5 15H3.33329V11.6667H12.5V15ZM12.5 10.8334H3.33329V7.50004H12.5V10.8334ZM16.6666 15H13.3333V7.50004H16.6666V15Z'
  // },

  // close: {
  //   width: 36,
  //   height: 36,
  //   d: 'M15.2 2.25033L13.7498 0.800049L8.00005 6.54976L2.25033 0.800049L0.800049 2.25033L6.54976 8.00005L0.800049 13.7498L2.25033 15.2L8.00005 9.45033L13.7498 15.2L15.2 13.7498L9.45033 8.00005L15.2 2.25033Z'
  // },
  // back: {
  //   width: 36,
  //   height: 36,
  //   d: 'M30 17H11.745L20.13 8.615L18 6.5L6 18.5L18 30.5L20.115 28.385L11.745 20H30V17Z'
  // },
  // arrow: {
  //   width: 24,
  //   height: 24,
  //   paths: [
  //     {
  //       style: 'fill:#FFFFFF00',
  //       d: 'M23.5 12C23.5 18.3513 18.3513 23.5 12 23.5C5.64873 23.5 0.5 18.3513 0.5 12C0.5 5.64873 5.64873 0.5 12 0.5C18.3513 0.5 23.5 5.64873 23.5 12Z'
  //     },
  //     {
  //       d: 'M15.41 7.41L14 6L8 12L14 18L15.41 16.59L10.83 12L15.41 7.41Z'
  //     }
  //   ]
  // },
  // edit: {
  //   width: 36,
  //   height: 36,
  //   d: 'M10.5 22.3751V25.5001H13.625L22.8417 16.2835L19.7167 13.1585L10.5 22.3751ZM25.2583 13.8668C25.5833 13.5418 25.5833 13.0168 25.2583 12.6918L23.3083 10.7418C22.9833 10.4168 22.4583 10.4168 22.1333 10.7418L20.6083 12.2668L23.7333 15.3918L25.2583 13.8668Z'
  // },
  // trash: {
  //   width: 36,
  //   height: 36,
  //   d: 'M12 25C12 26.1 12.9 27 14 27H22C23.1 27 24 26.1 24 25V13H12V25ZM14.46 17.88L15.87 16.47L18 18.59L20.12 16.47L21.53 17.88L19.41 20L21.53 22.12L20.12 23.53L18 21.41L15.88 23.53L14.47 22.12L16.59 20L14.46 17.88ZM21.5 10L20.5 9H15.5L14.5 10H11V12H25V10H21.5Z'
  // },

  // users: {
  //   width: 20,
  //   height: 20,
  //   d: 'M2.5 4.16667V15.8333C2.5 16.75 3.24167 17.5 4.16667 17.5H15.8333C16.75 17.5 17.5 16.75 17.5 15.8333V4.16667C17.5 3.25 16.75 2.5 15.8333 2.5H4.16667C3.24167 2.5 2.5 3.25 2.5 4.16667ZM12.5 7.5C12.5 8.88333 11.3833 10 10 10C8.61667 10 7.5 8.88333 7.5 7.5C7.5 6.11667 8.61667 5 10 5C11.3833 5 12.5 6.11667 12.5 7.5ZM5 14.1667C5 12.5 8.33333 11.5833 10 11.5833C11.6667 11.5833 15 12.5 15 14.1667V15H5V14.1667Z'
  // },
  // tenantUsers: {
  //   width: 20,
  //   height: 20,
  //   d: 'M17.4997 4.16669V15.8334H19.1663V4.16669H17.4997ZM14.1663 15.8334H15.833V4.16669H14.1663V15.8334ZM11.6663 4.16669H1.66634C1.20801 4.16669 0.833008 4.54169 0.833008 5.00002V15C0.833008 15.4584 1.20801 15.8334 1.66634 15.8334H11.6663C12.1247 15.8334 12.4997 15.4584 12.4997 15V5.00002C12.4997 4.54169 12.1247 4.16669 11.6663 4.16669ZM6.66634 6.45835C7.69967 6.45835 8.54134 7.30002 8.54134 8.33335C8.54134 9.36669 7.69967 10.2084 6.66634 10.2084C5.63301 10.2084 4.79134 9.36669 4.79134 8.33335C4.79134 7.30002 5.63301 6.45835 6.66634 6.45835ZM10.4163 14.1667H2.91634V13.5417C2.91634 12.2917 5.41634 11.6667 6.66634 11.6667C7.91634 11.6667 10.4163 12.2917 10.4163 13.5417V14.1667Z'
  // },

  // longStayAlert: {
  //   width: 24,
  //   height: 24,
  //   d: 'M2 12C2 6.48 6.47 2 11.99 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 11.99 22C6.47 22 2 17.52 2 12ZM4 12C4 16.42 7.58 20 12 20C16.42 20 20 16.42 20 12C20 7.58 16.42 4 12 4C7.58 4 4 7.58 4 12ZM11 7H12.5V12.25L17 14.92L16.25 16.15L11 13V7Z'
  // },
  // failureAlert: {
  //   width: 24,
  //   height: 24,
  //   paths: [
  //     {
  //       style: 'fill:rgba(0,0,0,0.25)',
  //       d: 'M22 8V2L2 22H18V8H22Z'
  //     },
  //     {
  //       style: 'fill:#616161',
  //       d: 'M20 10V18H22V10H20ZM12 22V12L2 22H12ZM20 22H22V20H20V22Z'
  //     }
  //   ]
  // },
  // lowBatteryAlert: {
  //   width: 24,
  //   height: 24,
  //   d: 'M15.67 4H14V2H10V4H8.33C7.6 4 7 4.6 7 5.33V20.66C7 21.4 7.6 22 8.33 22H15.66C16.4 22 17 21.4 17 20.67V5.33C17 4.6 16.4 4 15.67 4ZM13 18H11V16H13V18ZM13 14H11V9H13V14Z'
  // },

  // checkboxOff: {
  //   width: 24,
  //   height: 24,
  //   d: 'M19 5V19H5V5H19ZM19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3Z'
  // },
  // checkboxOn: {
  //   width: 24,
  //   height: 24,
  //   d: 'M19 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.89 21 5 21H19C20.11 21 21 20.1 21 19V5C21 3.9 20.11 3 19 3ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
  // },
  // userCircle: {
  //   width: 24,
  //   height: 24,
  //   d: 'M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 5C13.66 5 15 6.34 15 8C15 9.66 13.66 11 12 11C10.34 11 9 9.66 9 8C9 6.34 10.34 5 12 5ZM12 19.2C9.5 19.2 7.29 17.92 6 15.98C6.03 13.99 10 12.9 12 12.9C13.99 12.9 17.97 13.99 18 15.98C16.71 17.92 14.5 19.2 12 19.2Z'
  // },
  // headerArrow: {
  //   width: 36,
  //   height: 36,
  //   d: 'M13.41 14.5898L18 19.1698L22.59 14.5898L24 15.9998L18 21.9998L12 15.9998L13.41 14.5898Z'
  // },
  // companySelect: {
  //   width: 24,
  //   height: 24,
  //   d: "M4 10V17H7V10H4ZM10 10V17H13V10H10ZM2 22H21V19H2V22ZM16 10V17H19V10H16ZM11.5 1L2 6V8H21V6L11.5 1Z"
  // },
  // reload: {
  //   width: 21,
  //   height: 21,
  //   d: "M18.5333 3.46683C16.6 1.5335 13.9467 0.333496 11 0.333496C5.10668 0.333496 0.34668 5.10683 0.34668 11.0002C0.34668 16.8935 5.10668 21.6668 11 21.6668C15.9733 21.6668 20.12 18.2668 21.3067 13.6668H18.5333C17.44 16.7735 14.48 19.0002 11 19.0002C6.58668 19.0002 3.00001 15.4135 3.00001 11.0002C3.00001 6.58683 6.58668 3.00016 11 3.00016C13.2133 3.00016 15.1867 3.92016 16.6267 5.3735L12.3333 9.66683H21.6667V0.333496L18.5333 3.46683Z"
  // },
  loading: {
    width: 30,
    height: 42,
    d: "M16.0001 10.5001V16.2501L23.6667 8.58341L16.0001 0.916748V6.66675C7.52841 6.66675 0.666748 13.5284 0.666748 22.0001C0.666748 25.0092 1.54841 27.8076 3.04341 30.1651L5.84175 27.3667C4.97925 25.7759 4.50008 23.9359 4.50008 22.0001C4.50008 15.6559 9.65591 10.5001 16.0001 10.5001ZM28.9567 13.8351L26.1584 16.6334C27.0017 18.2434 27.5001 20.0642 27.5001 22.0001C27.5001 28.3442 22.3442 33.5001 16.0001 33.5001V27.7501L8.33341 35.4167L16.0001 43.0834V37.3334C24.4717 37.3334 31.3334 30.4717 31.3334 22.0001C31.3334 18.9909 30.4517 16.1926 28.9567 13.8351Z"
  },
  checked: {
    width: 24,
    height: 24,
    d: "M8.9999 16.2L4.7999 12L3.3999 13.4L8.9999 19L20.9999 6.99998L19.5999 5.59998L8.9999 16.2Z"
  },
  wifi: {
    width: 18,
    height: 12,
    d: "M0.75 3.75031L2.25 5.25031C5.9775 1.52281 12.0225 1.52281 15.75 5.25031L17.25 3.75031C12.6975 -0.802188 5.31 -0.802188 0.75 3.75031ZM6.75 9.75031L9 12.0003L11.25 9.75031C10.0125 8.50531 7.995 8.50531 6.75 9.75031ZM5.25 8.25031L3.75 6.75031C6.6525 3.85531 11.355 3.85531 14.25 6.75031L12.75 8.25031C10.68 6.18031 7.32 6.18031 5.25 8.25031Z",
    style: 'fill:#A2A7B5'
  },
  nonsmoke: {
    width: 16,
    height: 17,
    d: "M9.875 5.525H11.0225C11.81 5.525 12.5 6.08 12.5 7.0625V8H13.625V6.8075C13.625 5.4575 12.425 4.4375 11.0225 4.4375H9.875C9.11 4.4375 8.4875 3.7025 8.4875 2.9375C8.4875 2.1725 9.11 1.625 9.875 1.625V0.5C8.4875 0.5 7.3625 1.625 7.3625 3.0125C7.3625 4.4 8.4875 5.525 9.875 5.525ZM13.8875 0.875C13.8875 1.5725 13.6025 2.2025 13.1375 2.66C14.54 3.3275 15.5 4.7675 15.5 6.44V8H14.375V6.44C14.375 4.76 13.055 3.3875 11.375 3.3875V2.2625C12.14 2.2625 12.7625 1.64 12.7625 0.875H13.8875ZM5.7425 8.75L0.5 3.5L1.4375 2.5625L14.1875 15.3125L13.2425 16.25L7.9925 11H0.5V8.75H5.7425ZM15.5 8.75H14.375V11H15.5V8.75ZM12.5 8.75H13.625V11H12.5V8.75ZM11.75 8.75V10.9475L9.5525 8.75H11.75Z",
    style: "fill:#A2A7B5"
  },
  smoke: {
    width: 16,
    height: 14,
    d: "M13.3944 4.63738C13.8826 4.157 14.1819 3.4955 14.1819 2.76312C14.1819 1.30625 13.0006 0.125 11.5437 0.125V1.30625C12.347 1.30625 13.0006 1.95987 13.0006 2.76312C13.0006 3.56638 12.347 4.22 11.5437 4.22V5.40125C13.3077 5.40125 14.6938 6.84238 14.6938 8.60638V10.3625H15.875V8.5985C15.875 6.85025 14.867 5.33825 13.3944 4.63738ZM0.125 11.15H11.9375V13.5125H0.125V11.15ZM14.6937 11.15H15.875V13.5125H14.6937V11.15ZM13.9062 11.15H12.725V13.5125H13.9062V11.15ZM9.9688 6.58253H11.1737C12.6463 6.58253 13.9063 7.64565 13.9063 9.07103V10.3625H12.7251V9.33878C12.7251 8.30715 12.0006 7.7244 11.1737 7.7244H9.9688C8.51193 7.7244 7.33068 6.54315 7.33068 5.08628C7.33068 3.6294 8.51193 2.44815 9.9688 2.44815V3.6294C9.16555 3.6294 8.51193 4.20428 8.51193 5.00753C8.51193 5.81078 9.16555 6.58253 9.9688 6.58253Z",
    style: "fill:#A2A7B5",
  },
  powerSupply: {
    width: 9,
    height: 13.5,
    d: "M8.0075 3.25L8 0.25H6.5V3.25H3.5V0.25H2V3.25H1.9925C1.25 3.2425 0.5 3.9925 0.5 4.7425V8.86L3.125 11.5V13.75H6.875V11.5L9.5 8.8675V4.7425C9.5 3.9925 8.75 3.2425 8.0075 3.25Z",
    style: "fill:#A2A7B5",
  },
  "angle-bracket-right": {
    width: 8,
    height: 12,
    d: "M0.000156526 10.59L1.41016 12L7.41016 6L1.41016 -5.24537e-07L0.000157328 1.41L4.58016 6L0.000156526 10.59Z",
    style: "fill:#4E4E4E",
  },
  checkCircle: {
    width: 24,
    height: 24,
    d: "M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM10 14.17L16.59 7.58L18 9L10 17L6 13L7.41 11.59L10 14.17Z",
    style: "fill:white"
  },
  bell: {
    width: 20,
    height: 20,
    d: "M15.97 8.5C15.97 5.43 14.33 2.86 11.47 2.18V1.5C11.47 0.67 10.8 0 9.97 0C9.14 0 8.47 0.67 8.47 1.5V2.18C5.6 2.86 3.97 5.42 3.97 8.5V13.5L1.97 15.5V16.5H17.97V15.5L15.97 13.5V8.5ZM4.12 0.15L5.55 1.58C3.51 3.03 2.15 5.35 2 8H0C0.14 4.8 1.72 1.98 4.12 0.15ZM19.94 8H17.94C17.79 5.35 16.42 3.03 14.4 1.58L15.82 0.15C18.21 1.98 19.79 4.8 19.94 8ZM10.37 19.46C10.24 19.49 10.11 19.5 9.97 19.5C8.86 19.5 7.97 18.6 7.96 17.5H11.96C11.96 17.78 11.91 18.04 11.81 18.28C11.55 18.88 11.02 19.32 10.37 19.46Z",
    style: "fill:white"
  },


  // closebtn: {
  //   width: 16,
  //   height: 16,
  //   d: "M17.0999 2.5316L15.4683 0.900024L8.9999 7.36845L2.53147 0.900024L0.899902 2.5316L7.36833 9.00002L0.899902 15.4685L2.53147 17.1L8.9999 10.6316L15.4683 17.1L17.0999 15.4685L10.6315 9.00002L17.0999 2.5316Z"
  // },
  // dragger: {
  //   width: 15,
  //   height: 9,
  //   d: "M0.5 8.125V9.625H15.5V8.125H0.5ZM0.5 4.375V5.875H15.5V4.375H0.5ZM0.5 0.625V2.125H15.5V0.625H0.5Z"
  // },
  // search: {
  //   width: 24,
  //   height: 24,
  //   d: "M15.5 14H14.71L14.43 13.73C15.41 12.59 16 11.11 16 9.5C16 5.91 13.09 3 9.5 3C5.91 3 3 5.91 3 9.5C3 13.09 5.91 16 9.5 16C11.11 16 12.59 15.41 13.73 14.43L14 14.71V15.5L19 20.49L20.49 19L15.5 14ZM9.5 14C7.01 14 5 11.99 5 9.5C5 7.01 7.01 5 9.5 5C11.99 5 14 7.01 14 9.5C14 11.99 11.99 14 9.5 14Z",
  // },
  // searchclose: {
  //   width: 32,
  //   height: 32,
  //   d: "M23.1998 10.2501L21.7495 8.7998L15.9998 14.5495L10.2501 8.7998L8.7998 10.2501L14.5495 15.9998L8.7998 21.7495L10.2501 23.1998L15.9998 17.4501L21.7495 23.1998L23.1998 21.7495L17.4501 15.9998L23.1998 10.2501Z"
  // },

  // expandMore: {
  //   width: 24,
  //   height: 24,
  //   d: 'M0 0h24v24H0z'
  // },
  // midloading: {
  //   width: 46,
  //   height: 46,
  //   d: "M23 11.5001V17.2501L30.6667 9.58341L23 1.91675V7.66675C14.5283 7.66675 7.66666 14.5284 7.66666 23.0001C7.66666 26.0092 8.54832 28.8076 10.0433 31.1651L12.8417 28.3667C11.9792 26.7759 11.5 24.9359 11.5 23.0001C11.5 16.6559 16.6558 11.5001 23 11.5001ZM35.9567 14.8351L33.1583 17.6334C34.0017 19.2434 34.5 21.0642 34.5 23.0001C34.5 29.3442 29.3442 34.5001 23 34.5001V28.7501L15.3333 36.4167L23 44.0834V38.3334C31.4717 38.3334 38.3333 31.4717 38.3333 23.0001C38.3333 19.9909 37.4517 17.1926 35.9567 14.8351Z"
  // }
})
