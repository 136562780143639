export interface SpaceTypeStatusArgs {
  readonly id: string;
  readonly vacant: boolean;
  readonly queueCount: number;
}

export default class SpaceTypeStatus {
  readonly id: string
  readonly vacant: boolean
  readonly queueCount: number

  constructor(props: SpaceTypeStatusArgs) {
    this.id = props.id;
    this.vacant = props.vacant;
    this.queueCount = props.queueCount;
  }
}