import Vue from 'vue';
import VeeValidate, { Validator } from 'vee-validate';

Validator.localize('ja', require('vee-validate/dist/locale/ja')); //eslint-disable-line @typescript-eslint/no-var-requires
Vue.use(VeeValidate, { locale: 'ja' });

Validator.extend('has_uppercase', {
  getMessage: (field: string) => field + 'に英大文字が含まれていません',
  validate: (value: any) => /[A-Z]/.test(value),
});
Validator.extend('has_lowercase', {
  getMessage: (field: string) => field + 'に英小文字が含まれていません',
  validate: (value: any) => /[a-z]/.test(value),
});
Validator.extend('has_number', {
  getMessage: (field: string) => field + 'に数字が含まれていません',
  validate: (value: any) => /[\d]/.test(value),
});
Validator.extend('only_text', {
  getMessage: (_: string) => '記号は使用できません',
  validate: (value: any) =>
    /^[^<>\?/.,'";:\\|\]\[}{=\+\-\_\)\*\(\&\^\%\$\#\@\!\~\`]+$/.test(value), //eslint-disable-line no-useless-escape
});
Validator.extend('is_2_bits_number', {
  getMessage: (_: string) => '半角数字２桁以内にしてください',
  validate: (value: any) => /^[0-9]{1,2}$/.test(value),
});
Validator.extend('time', {
  getMessage: (field: string) => field + 'が時刻のフォーマットではありません',
  validate: (value: any) => /\d{2}:\d{2}(:\d{2})/.test(value),
});
Validator.extend('alphabet_numeric', {
  getMessage: (_: string) => '半角英数字のみしてください',
  validate: (value: any) => /^[a-zA-Z0-9]+$/.test(value),
});
Validator.extend('at_least_alphabet_numeric', {
  validate: (value: any) => /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/.test(value),
});
