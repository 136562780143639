import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Cover",
    component: () => import(/* webpackChunkName: "cover" */ "@/views/screens/cover/CoverScreen.vue"),
  },
  {
    path: "/introduction/:chainId",
    props: true,
    name: "Introduction",
    component: () => import(/* webpackChunkName: "introduction" */ "@/views/introduction.vue"),
  },
  {
    path: "/stores/:chainId",
    props: true,
    component: () => import(/* webpackChunkName: "stores" */ "@/views/Stores.vue"),
  },
//  {
//    path: "/stores",
//    props: true,
//    component: () => import(/* webpackChunkName: "stores" */ "@/views/Stores.vue"),
//  },

  {
    path: "/reservation/conditions",
    redirect: "/store/1",
  },
  {
    path: "/reservation/store/:storeId",
    redirect: "/store/1",
    //name: "ReservationConditions",
    //props: true,
    //component: () => import(/* webpackChunkName: "reservation-space-group" */ "@/views/screens/reservation/ReservationConditionsScreen.vue"),
  },

  {
    path: "/reservation/request/:spaceGroupId",
    redirect: "/store/1",
    //name: "ReservationRequest",
    //props: true,
    //component: () => import(/* webpackChunkName: "reservation-request" */ "@/views/screens/reservation/ReservationRequestScreen.vue"),
  },
  {
    path: "/reservation/mail_confirmation/:storeId/:spaceGroupId",
    redirect: "/store/1",
  //  name: "MailConfirmation",
//    props: true,
//    component: () => import(/* webpackChunkName: "reservation-mail-confirmation" */ "@/views/screens/reservation/MailConfirmationScreen.vue"),
  },
  {
    path: "/reservation/verify/:token",
    redirect: "/store/1",
//    name: "ReservationVerify",
//    props: true,
//    component: () => import(/* webpackChunkName: "reservation-verify" */ "@/views/screens/reservation/ReservationVerifyScreen.vue"),
  },
  {
    path: "/reservation/status/:token",
    redirect: "/store/1",
    //name: "ReservationStatus",
    //props: true,
    //component: () => import(/* webpackChunkName: "reservation-status" */ "@/views/screens/reservation/ReservationStatusScreen.vue"),
  },
  {
    path: "/top/:chainId",
    props: true,
    component: () => import(/* webpackChunkName: "cover" */ "@/views/screens/cover/CoverScreen.vue"),
  },
 // {
   // path: "/stripe",
   // component: () => import(/* webpackChunkName: "stripe-test" */ "@/views/stripe.vue"),
  //},
  {
    path: "/signup-request/:chainId/:spaceGroupId",
    props: true,
    component: () => import(/* webpackChunkName: "signup-request" */ "@/views/signup-request.vue"),
  },

  {
    path: "/signup-request/:chainId",
    props: true,
    component: () => import(/* webpackChunkName: "signup-request" */ "@/views/signup-request.vue"),
  },
  {
    path: "/signup",
    component: () => import(/* webpackChunkName: "signup" */ "@/views/signup.vue"),
  },
  {
    path: "/forgot-request/:chainId",
    props: true,
    component: () => import(/* webpackChunkName: "forgot-request" */ "@/views/forgot-request.vue"),
  },
  {
    path: "/forgot",
    component: () => import(/* webpackChunkName: "forgot" */ "@/views/forgot.vue"),
  },
  {
    path: "/signin/:chainId/:spaceGroupId",
    props: true,
    component: () => import(/* webpackChunkName: "signin" */ "@/views/signin.vue"),
  },
  {
    path: "/signin/:chainId",
    props: true,
    component: () => import(/* webpackChunkName: "signin" */ "@/views/signin.vue"),
  },
  //{
  //  path: "/signin/",
  //  component: () => import(/* webpackChunkName: "signin" */ "@/views/signin.vue"),
  //},






  {
    path: "/account/payment-method",
    component: () => import(/* webpackChunkName: "account-payment-method" */ "@/views/account/payment-method.vue"),
  },
  {
    path: "/account/history",
    component: () => import(/* webpackChunkName: "account-history" */ "@/views/account/history.vue"),
  },
  {
    path: "/account/top",
    component: () => import(/* webpackChunkName: "account-top" */ "@/views/account/top.vue"),
  },
  {
    path: "/faq",
    component: () => import(/* webpackChunkName: "faq" */ "@/views/faq.vue"),
  },
  {
    path: "/terms",
    component: () => import(/* webpackChunkName: "terms" */ "@/views/terms.vue"),
  },
  {
    path: "/specified-transaction",
    component: () => import(/* webpackChunkName: "specified-transaction" */ "@/views/specified-transaction.vue"),
  },
  {
    path: "/reservation/:reservationId/extension",
    props: true,
    component: () => import(/* webpackChunkName: "reservation-extension" */ "@/views/reservation-extension.vue"),
  },

  {
    path: "/reservation/:chainId/:reservationId",
    props: true,
    component: () => import(/* webpackChunkName: "reservation" */ "@/views/reservation.vue"),
  },
  {//deprecated use reserve-seat
    path: "/reserve/:spaceGroupId",
    props: true,
    component: () => import(/* webpackChunkName: "space-group" */ "@/views/space-group.vue"),
  },
  {
    path: "/reserve-seat/:spaceGroupIdHash",
    props: true,
    component: () => import(/* webpackChunkName: "space-group" */ "@/views/space-group-v2.vue"),
  },
  {//deprecated use /location/
    path: "/store/:storeId",
    props: true,
    component: () => import(/* webpackChunkName: "space-group-list" */ "@/views/space-group-list.vue"),
  },
  {
    path: "/location/:storeIdHash",
    props: true,
    component: () => import(/* webpackChunkName: "space-group-list" */ "@/views/space-group-list-v2.vue"),
  },
  {
    path: "/chain-filter/:chainId",
    props: true,
    component: () => import(/* webpackChunkName: "chain-filter" */ "@/views/chain-filter.vue"),
  },
  {
    path: "/interior/:spaceGroupIdHash",
    props: true,
    component: () => import(/* webpackChunkName: "interior" */ "@/views/interior.vue"),
  },
  {
    path: "/walkin/:walkinId/extension/result",
    props: true,
    component: () => import(/* webpackChunkName: "extension-result" */ "@/views/walkin-extension-result.vue"),
  },
  {
    path: "/walkin/:walkinId/extension/top",
    props: true,
    component: () => import(/* webpackChunkName: "extension-top" */ "@/views/walkin-extension-top.vue"),
  },
  {
    path: "/walkin/:walkinId/extension",
    props: true,
    component: () => import(/* webpackChunkName: "extension" */ "@/views/walkin-extension.vue"),
  },
  {//delete after the test
    path: "/tmp/sso-test",
    component: () => import(/* webpackChunkName: "extension" */ "@/views/sso-test.vue"),
  },
  {
    path: "/not-found",
    props: true,
    component: () => import(/* webpackChunkName: "not-found" */ "@/views/not-found.vue"),
  },

];

if( process.env.NODE_ENV === 'development' ){
  routes.push( {
    path: "/test",
    name: "test",
    component: () => import( '@/views/test.vue' ),
  } );
  routes.push( {
    path: "/test2",
    name: "test2",
    component: () => import( '@/views/test2.vue' ),
  } );

}

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
