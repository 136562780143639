import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueHead from "vue-head";
import "./icons";
import Icon from "vue-awesome/components/Icon";
import client from "@/infrastructure/api/client";
import * as api from "@/infrastructure/api";
import * as memory from "@/infrastructure/memory";
import "@/gtags";

Vue.config.productionTip = false;

client.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
client.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
client.defaults.headers.post["Access-Control-Allow-Origin"] = process.env.VUE_APP_ACCESS_CONTROL_ALLOW_ORIGIN;

Vue.prototype.$repositories = {
  ShopRepository: new api.ShopService(client),
  ReservationRepository: new api.ReservationService(client),
  SpaceTypeCacheRepository: new memory.SpaceTypeCacheService(),
};

Vue.use(VueHead);

Vue.component("Icon", Icon);

import '@/validator';

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
