import { AxiosInstance } from "axios";
import ShopRepository from "@/repositories/shop_repository";
import { Shop } from "@/entities";

export default class ShopService implements ShopRepository {
  client?: AxiosInstance;

  constructor(client: AxiosInstance) {
    this.client = client;
  }

  get(code: string): Promise<Shop> {
    return new Promise((resolve) => {
      const shop = {
        code: code,
        name: "スターバックスコーヒー CIRCLES銀座店",
      };
      resolve(shop);
    });
  }
}
