import { SpaceType } from "@/entities";
import { SpaceTypeCacheRepository } from "@/repositories";

export default class SpaceTypeCacheService implements SpaceTypeCacheRepository {
  spaceType?: any;

  constructor() {
    this.spaceType = undefined;
  }

  save(spaceType: any): void {
    this.spaceType = spaceType;
  }

  get(): any | undefined {
    return this.spaceType;
  }
}
