import axios from "axios";
import { NotFoundError, ConflictError, UnknownError } from "@/entities/errors";

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    switch (error.status) {
      case 404:
        throw new NotFoundError(error.response.data["message"]);
      case 409:
        throw new ConflictError(error.response.data["message"]);
      default:
        throw new UnknownError(error.response?.data["message"] || "サーバーとの通信中に問題が発生しました。");
    }
  },
);

export default axios;
