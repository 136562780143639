export interface ReservationStatusArgs {
  expires_at: string;
  status: string;
  queueCount?: number;
}

export default class ReservationStatus {
  readonly expiresAt: string;
  readonly status: string;
  readonly queueCount?: number;

  constructor(props: ReservationStatusArgs) {
    this.expiresAt = props.expires_at;
    this.status = props.status;
    this.queueCount = props.queueCount;
  }
}
