const pendingVerification = "PENDING_VERIFICATION";
const verificationExpired = "VERIFICATION_EXPIRED";
const reserved = "RESERVED";
const reservationExpired = "RESERVATION_EXPIRED";
const cancelled = "CANCELLED";
const using = "USING";
const manualLeft = "MANUAL_LEFT";
const autoLeft = "AUTO_LEFT";

export interface ReservationArgs {
  name?: string;
  table_type: string;
  capacity: number;
  power_supply: boolean;
  wifi: boolean;
  smoke: boolean;
  passcode?: string;
  expires_at: string;
  status: string;
  queueCount?: number;
  expireMin?: number;
  store: any;
  spaceGroup: any;
  chain: any;
  claimTime: number;
  showCloseWarning: boolean;
  closeMinutes: number;
  isSuspended: boolean;
  maxSpaceCount: number;
}

export default class Reservation {
  readonly name?: string;
  readonly tableType: string;
  readonly capacity: number;
  readonly powerSupply: boolean;
  readonly wifi: boolean;
  readonly smoke: boolean;
  readonly passcode?: string;
  expiresAt: string;
  status: string;
  queueCount?: number;
  expireMin?: number;
  readonly store: any;
  readonly spaceGroup: any;
  readonly chain: any;
  readonly claimTime?: number;
  readonly showCloseWarning: boolean;
  readonly closeMinutes: number;
  readonly isSuspended: boolean;
  readonly maxSpaceCount: number;

  constructor(props: ReservationArgs) {
    this.name = props.name;
    this.tableType = props.table_type;
    this.capacity = props.capacity;
    this.powerSupply = props.power_supply;
    this.wifi = props.wifi;
    this.smoke = props.smoke;
    this.passcode = props.passcode;
    this.expiresAt = props.expires_at;
    this.status = props.status;
    this.queueCount = props.queueCount;
    this.store = props.store;
    this.spaceGroup = props.spaceGroup;
    this.chain = props.chain;
    this.claimTime = props.claimTime;
    this.showCloseWarning = props.showCloseWarning;
    this.closeMinutes = props.closeMinutes;
    this.isSuspended = props.isSuspended;
    this.maxSpaceCount = props.maxSpaceCount;

    this.expireMin = props.expireMin;
    if( this.status === 'IN_QUEUE' ){
      this.passcode = '';
      this.name = '';
    }
  }

  public isPendingVerification(): boolean {
    return this.status === pendingVerification;
  }

  public isVerificationExpired(): boolean {
    return this.status === verificationExpired;
  }

  public isReserved(): boolean {
    return this.status === reserved;
  }

  public isReservationExpired(): boolean {
    return this.status === reservationExpired;
  }

  public isCancelled(): boolean {
    return this.status === cancelled;
  }

  public isUsing(): boolean {
    return this.status === using;
  }

  public isManualLeft(): boolean {
    return this.status === manualLeft;
  }

  public isAutoLeft(): boolean {
    return this.status === autoLeft;
  }

}
