import { AxiosInstance } from 'axios';
import {
  SpaceType,
  SpaceTypeStatus,
  Reservation,
  ReservationStatus,
} from "@/entities";
import {
  ReservationRepository,
  CreateReservationRequest,
} from "@/repositories";
import { SpaceTypeArgs } from "@/entities/space_type";
import { SpaceTypeStatusArgs } from "@/entities/space_type_status";
import { ReservationArgs } from "@/entities/reservation";
import { ReservationStatusArgs } from "@/entities/reservation_status";

export default class ReservationService implements ReservationRepository {
  client!: AxiosInstance

  constructor(client: AxiosInstance) {
    this.client = client
  }

  create(request: CreateReservationRequest): Promise<void> {
    return this.client.post(`/v1/web/unverified/reservation`, {
//      'table_type': request.tableType,
//      'power_supply': request.powerSupply,
//      wifi: request.wifi,/
//      smoke: request.smoke,
//      capacity: request.capacity,
      spaceGroupId: request.spaceGroupId,
      email: request.email,
      queue: request.queue,
    });
  }

  getSpaceTypes(): Promise<any> {
    return this.client.get<any>(`/v1/web/space_types`).then((response) => {
    //return response.data.map((d: any) => new SpaceType({ ...d }));
      return response.data;
      //return response.data.map((d: any) => new SpaceType({ ...d }));
      //return {
      //  spaceGroups: response.data.map((d: any) => new SpaceType({ ...d })),
        //queueCount: response.data.queueCount,
      //};
    });
  }

  getSpaceTypeStatuses(): Promise<any> {
    return this.client
      .get<any>(`/v1/web/space_type/statuses`)
      .then((response) => {
        //return response.data;
        return response.data.map((d: any) => new SpaceTypeStatus({ ...d }));
        //return {
        //  spaceGroups: response.data.spaceGroups.map((d: any) => new SpaceTypeStatus({ ...d })),
        //  queueCount: response.data.queueCount,
       // }
      });
  }

  getSpaceTypeStatus(spaceType: any): Promise<SpaceTypeStatus> {
    return this.client
      .get<SpaceTypeStatusArgs>(`/v1/web/space_type/status`, {
        params: {
          'table_type': spaceType.tableType,
          capacity: spaceType.capacity,
          'power_supply': spaceType.powerSupply,
          wifi: spaceType.wifi,
          smoke: spaceType.smoke,
        },
      })
      .then((response) => {
        return new SpaceTypeStatus({ ...response.data });
      });
  }

  getSpaceGroup( spaceGroupId: number | string ): Promise<any> {
    return this.client
      .get<any>(`/v1/web/space-group/${spaceGroupId}`).then( (r) => {
        console.dir( r.data );
        return r.data;
      } );
  }

  verifyReservation(token: string): Promise<Reservation> {
    return this.client
      .post<ReservationArgs>(`/v1/web/reservations/_verify`, {
        token,
      })
      .then((response) => {
        return new Reservation({ ...response.data });
      });
  }

  getReservation(token: string): Promise<any> {
    return this.client
      .get<ReservationArgs>(`/v1/web/reservation/${token}`)
      .then((response) => {
        //return response.data;
        return new Reservation({ ...response.data });
      });
  }

  getReservationStatus(token: string): Promise<ReservationStatus> {
    return this.client
      .get<ReservationStatusArgs>(`/v1/web/reservation-status/${token}`)
      .then((response) => {
        return new ReservationStatus({ ...response.data });
      });
  }

  cancelReservation(token: string): Promise<void> {
    return this.client
      .post(`/v1/web/reservations/_cancel`, {
        token,
      });
  }
}
